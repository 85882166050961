(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('FileMetaDataDetailController', FileMetaDataDetailController);

    FileMetaDataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FileMetaData'];

    function FileMetaDataDetailController($scope, $rootScope, $stateParams, previousState, entity, FileMetaData) {
        var vm = this;

        vm.fileMetaData = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('asopenessaApp:fileMetaDataUpdate', function(event, result) {
            vm.fileMetaData = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('AssociateDetailController', AssociateDetailController);

    AssociateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Associate', 'FileMetaData'];

    function AssociateDetailController($scope, $rootScope, $stateParams, previousState, entity, Associate, FileMetaData) {
        var vm = this;

        vm.associate = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('asopenessaApp:associateUpdate', function(event, result) {
            vm.associate = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('publicAnnouncements', publicAnnouncements);

    function publicAnnouncements() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/home/public-announcement/public-announcements.html',
            controller: controller,
            controllerAs: 'vm'
        };

        controller.$inject = ['PublicAnnouncement', 'AlertService', 'paginationConstants', 'CommonDateUtils'];

        function controller(PublicAnnouncement, AlertService, paginationConstants, CommonDateUtils) {

            var vm = this;

            vm.params = {
                page: 0,
                sort: 'publicationStartDate,desc'
            }

            vm.itemsPerPage = paginationConstants.maxItemsPerPage;

            loadAll();

            function loadAll() {

                PublicAnnouncement.query({
                    currentDate: CommonDateUtils.getCurrentDate(),
                    page: vm.params.page,
                    size: vm.itemsPerPage,
                    sort: vm.params.sort
                }, onSuccess, onError);
                function onSuccess(data, headers) {
                    vm.announcements = data;
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }

        }

        return directive;
    }
})();


(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('fileIconController', fileIconController)
        .directive('fileIcon', fileIcon);

    fileIconController.$inject = ['$scope', 'FileIconService'];

    function fileIconController($scope, FileIconService) {

        var vm = this;
        vm.fileExtension = $scope.fileExtension;
        vm.iconClass = FileIconService.getFileExtension(vm.fileExtension)
    }

    function fileIcon() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/common/file-icon.html',
            controller: fileIconController,
            controllerAs: 'vm',
            scope: {
                fileExtension: '='
            }
        };
        return directive;
    }

})();

(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('galleries', galleries);


    function galleries() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/associate-gallery/galleries.html'
        };

        return directive;

    }
})();


(function() {
    'use strict';
    angular
        .module('asopenessaApp')
        .factory('AsoDocument', AsoDocument);

    AsoDocument.$inject = ['$resource'];

    function AsoDocument ($resource) {
        var resourceUrl =  'api/documents/asopenessa';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('AssociateGalleryController', AssociateGalleryController);

    AssociateGalleryController.$inject = ['$state', 'Gallery', 'AlertService', 'paginationConstants'];

    function AssociateGalleryController($state, Gallery, AlertService, paginationConstants) {

        var vm = this;

        vm.params = {
        page: 0,
        sort: 'creationDate,desc'
        }

        vm.itemsPerPage = paginationConstants.maxItemsPerPage;

        loadAll();

        function loadAll() {

         Gallery.query({
             page: vm.params.page,
             size: vm.itemsPerPage,
             sort: vm.params.sort
         }, onSuccess, onError);
         function onSuccess(data, headers) {
             vm.galleries = data;
         }
         function onError(error) {
             AlertService.error(error.data.message);
         }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('AssociateDialogController', AssociateDialogController);

    AssociateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Associate', 'FileMetaData', 'FileUpload', '$window'];

    function AssociateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Associate, FileMetaData, FileUpload, $window) {
        var vm = this;

        vm.associate = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.profilepictures = FileMetaData.query({filter: 'associate-is-null'});

        vm.editFile = editFile;
        vm.cancelFileEdition = cancelFileEdition;
        vm.updateFile = updateFile;

        $q.all([vm.associate.$promise, vm.profilepictures.$promise]).then(function() {
            if (vm.associate.id && vm.associate.profilePictureId) {
                // edition
                $scope.editForm.file.$setValidity("required",true);
            }
            if (!vm.associate.profilePictureId) {
                return $q.reject();
            }
            return FileMetaData.get({id : vm.associate.profilePictureId}).$promise;
        }).then(function(profilePicture) {
            vm.profilepictures.push(profilePicture);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.associate.id !== null) {
                Associate.update(vm.associate, onSaveSuccess, onSaveError);
            } else {
                Associate.save(vm.associate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // file update if needed.
            if (vm.isFileEdition || !vm.associate.profilePictureId) {
                vm.associate.id = result.id
                vm.updateFile()
            } else {
                $scope.$emit('asopenessaApp:associateUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.birthDate = false;
        vm.datePickerOpenStatus.deathDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editFile () {
            vm.isFileEdition = true;
            $scope.editForm.file.$setValidity("required", false);
        }

        function cancelFileEdition () {
            angular.element("input[type='file']").val(null);
            $scope.editForm.file.$setValidity("maxSize", true);
            $scope.editForm.file.$setValidity("required", true);
            vm.file = null;
            vm.isFileEdition = false;
        }

        function updateFile() {
            FileUpload.save(vm.file, onFileUploadSuccess, onFileUploadError);
        }

        function onFileUploadSuccess(result) {
            vm.associate.profilePictureId = result.id
            vm.isFileEdition = false;
            Associate.update(vm.associate, onSaveSuccess, onSaveError);
        }

        function onFileUploadError(error) {
            console.log('File upload error', error)
        }
    }
})();

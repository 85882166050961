(function () {
    'use strict';
    //directiva del footer
    angular
        .module('asopenessaApp')
        .directive('asopenesaFooter', footer);

 function footer() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/footer/footer.html',
        };

        return directive;
    }
})();

(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('AboutUsController', AboutUsController);

    AboutUsController.$inject = ['$state', 'Employee', 'AlertService', 'paginationConstants'];

    function AboutUsController($state, Employee, AlertService, paginationConstants) {

        var vm = this;

        vm.params = {
         page: 0,
         sort: 'jobId,asc'
        }

        vm.itemsPerPage = paginationConstants.maxItemsPerPage;

        loadAll();

        function loadAll() {

            Employee.query({
                page: vm.params.page,
                size: vm.itemsPerPage,
                sort: vm.params.sort
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.employees = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('asopenessaApp')
        .factory('PublicAnnouncement', PublicAnnouncement);

    PublicAnnouncement.$inject = ['$resource'];

    function PublicAnnouncement ($resource) {
        var resourceUrl =  'api/announcements/public';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('EmployeeDialogController', EmployeeDialogController);

    EmployeeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Employee', 'FileMetaData', 'Job', 'FileUpload', '$window'];

    function EmployeeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Employee, FileMetaData, Job, FileUpload, $window) {
        var vm = this;

        vm.employee = entity;
        vm.clear = clear;
        vm.save = save;
        vm.profilepictures = FileMetaData.query({filter: 'employee-is-null'});

        vm.editFile = editFile;
        vm.cancelFileEdition = cancelFileEdition;
        vm.updateFile = updateFile;

        $q.all([vm.employee.$promise, vm.profilepictures.$promise]).then(function() {
            if (vm.employee.id && vm.employee.profilePictureId) {
                // edition
                $scope.editForm.file.$setValidity("required",true);
            }
            if (!vm.employee.profilePictureId) {
                return $q.reject();
            }
            return FileMetaData.get({id : vm.employee.profilePictureId}).$promise;
        }).then(function(profilePicture) {
            vm.profilepictures.push(profilePicture);
        });
        vm.jobs = Job.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.employee.id !== null) {
                Employee.update(vm.employee, onSaveSuccess, onSaveError);
            } else {
                Employee.save(vm.employee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // file update if needed.
            if (vm.isFileEdition || !vm.employee.profilePictureId) {
                vm.employee.id = result.id
                vm.updateFile()
            } else {
                $scope.$emit('asopenessaApp:employeeUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function editFile () {
            vm.isFileEdition = true;
            $scope.editForm.file.$setValidity("required", false);
        }

        function cancelFileEdition () {
            angular.element("input[type='file']").val(null);
            $scope.editForm.file.$setValidity("maxSize", true);
            $scope.editForm.file.$setValidity("required", true);
            vm.file = null;
            vm.isFileEdition = false;
        }

        function updateFile() {
            FileUpload.save(vm.file, onFileUploadSuccess, onFileUploadError);
        }

        function onFileUploadSuccess(result) {
            vm.employee.profilePictureId = result.id
            vm.isFileEdition = false;
            Employee.update(vm.employee, onSaveSuccess, onSaveError);
        }

        function onFileUploadError(error) {
            console.log('File upload error', error)
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('asopenessaApp')
        .factory('CoopDocument', CoopDocument);

    CoopDocument.$inject = ['$resource'];

    function CoopDocument ($resource) {
        var resourceUrl =  'api/documents/coopsayvi';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

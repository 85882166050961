(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('AnnouncementDialogController', AnnouncementDialogController);

    AnnouncementDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Announcement', 'FileMetaData', 'FileUpload', '$window'];

    function AnnouncementDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Announcement, FileMetaData, FileUpload, $window) {
        var vm = this;

        vm.announcement = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.images = FileMetaData.query({filter: 'announcement-is-null'});

        vm.editFile = editFile;
        vm.cancelFileEdition = cancelFileEdition;
        vm.updateFile = updateFile;

        $q.all([vm.announcement.$promise, vm.images.$promise]).then(function() {
            if (vm.announcement.id && vm.announcement.imageId) {
                // edition
                $scope.editForm.file.$setValidity("required",true);
            }
            if (!vm.announcement.imageId) {
                return $q.reject();
            }
            return FileMetaData.get({id : vm.announcement.imageId}).$promise;
        }).then(function(image) {
            vm.images.push(image);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.announcement.id !== null) {
                Announcement.update(vm.announcement, onSaveSuccess, onSaveError);
            } else {
                Announcement.save(vm.announcement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // file update if needed.
            if (vm.isFileEdition || !vm.announcement.imageId) {
                vm.announcement.id = result.id
                vm.updateFile()
            } else {
                $scope.$emit('asopenessaApp:announcementUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.publicationStartDate = false;
        vm.datePickerOpenStatus.publicationEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editFile () {
            vm.isFileEdition = true;
            $scope.editForm.file.$setValidity("required", false);
        }

        function cancelFileEdition () {
            angular.element("input[type='file']").val(null);
            $scope.editForm.file.$setValidity("maxSize", true);
            $scope.editForm.file.$setValidity("required", true);
            vm.file = null;
            vm.isFileEdition = false;
        }

        function updateFile() {
            FileUpload.save(vm.file, onFileUploadSuccess, onFileUploadError);
        }

        function onFileUploadSuccess(result) {
            vm.announcement.imageId = result.id
            vm.isFileEdition = false;
            Announcement.update(vm.announcement, onSaveSuccess, onSaveError);
        }

        function onFileUploadError(error) {
            console.log('File upload error', error)
        }
    }
})();

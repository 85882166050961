(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('announcementItem', announcementItem);

    announcementItemController.$inject = ['$scope'];

    function announcementItemController($scope) {

        var vm = this;
        vm.item = $scope.item
    }

    function announcementItem() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/entities/announcement/announcement-item.html',
            controller: announcementItemController,
            controllerAs: 'vm',
            scope: {
                item: '='
            }
        };
        return directive;
    }
})();


(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('documentItem', documentItem);

    documentItemController.$inject = ['$scope'];

    function documentItemController($scope) {

        var vm = this;
        vm.item = $scope.item
        vm.getURL = getURL

        function getDocumentFileId() {
            return $scope.item.documentFileId
        }

        function getDocumentTitle() {
            return $scope.item.title
        }

        function getURL() {
            if (!getDocumentFileId()) {
                return "";
            }
            return document.location.origin + '/api/files/' + getDocumentFileId() + '/' + getDocumentTitle();
        }
    }

    function documentItem() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/entities/document/document-item.html',
            controller: documentItemController,
            controllerAs: 'vm',
            scope: {
                item: '='
            }
        };
        return directive;
    }
})();

(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('DocumentDialogController', DocumentDialogController);

    DocumentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Document', 'FileMetaData', 'FileUpload', '$window'];

    function DocumentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Document, FileMetaData, FileUpload, $window) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.documentfiles = FileMetaData.query({filter: 'document-is-null'});

        vm.editFile = editFile;
        vm.cancelFileEdition = cancelFileEdition;
        vm.updateFile = updateFile;

        $q.all([vm.document.$promise, vm.documentfiles.$promise]).then(function() {
            if (vm.document.id && vm.document.documentFileId) {
                // edition
                $scope.editForm.file.$setValidity("required",true);
            }
            if (!vm.document.documentFileId) {
                return $q.reject();
            }
            return FileMetaData.get({id : vm.document.documentFileId}).$promise;
        }).then(function(documentFile) {
            vm.documentfiles.push(documentFile);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.document.id !== null) {
                Document.update(vm.document, onSaveSuccess, onSaveError);
            } else {
                Document.save(vm.document, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // file update if needed.
            if (vm.isFileEdition || !vm.document.documentFileId) {
                vm.document.id = result.id
                vm.updateFile()
            } else {
                $scope.$emit('asopenessaApp:documentUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.publicationStartDate = false;
        vm.datePickerOpenStatus.publicationEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editFile () {
            vm.isFileEdition = true;
            $scope.editForm.file.$setValidity("required", false);
        }

        function cancelFileEdition () {
            angular.element("input[type='file']").val(null);
            $scope.editForm.file.$setValidity("maxSize", true);
            $scope.editForm.file.$setValidity("required", true);
            vm.file = null;
            vm.isFileEdition = false;
        }

        function updateFile() {
            FileUpload.save(vm.file, onFileUploadSuccess, onFileUploadError);
        }

        function onFileUploadSuccess(result) {
            vm.document.documentFileId = result.id
            vm.isFileEdition = false;
            Document.update(vm.document, onSaveSuccess, onSaveError);
        }

        function onFileUploadError(error) {
            console.log('File upload error', error)
        }

    }
})();

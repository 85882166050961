(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('CoopDocumentController', CoopDocumentController);

    CoopDocumentController.$inject = ['$state', 'CoopDocument', 'AlertService', 'paginationConstants', 'CommonDateUtils'];

    function CoopDocumentController($state, CoopDocument, AlertService, paginationConstants, CommonDateUtils) {

        var vm = this;

        vm.params = {
            page: 0,
            sort: 'publicationStartDate,desc'
        }

        vm.itemsPerPage = paginationConstants.maxItemsPerPage;

        loadAll();

        function loadAll() {

            CoopDocument.query({
                currentDate: CommonDateUtils.getCurrentDate(),
                page: vm.params.page,
                size: vm.itemsPerPage,
                sort: vm.params.sort
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.documents = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();

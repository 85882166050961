(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('pictureController', pictureController)
        .directive('picture', picture);

    pictureController.$inject = ['$scope'];

    function pictureController($scope) {

        var vm = this;
        vm.getURL = getURL

        function getPictureId() {
            return $scope.pictureId
        }

        function getURL() {
            if (!getPictureId()) {
                return "";
            }
            return document.location.origin + '/api/files/' + getPictureId() + '/' + getPictureId();
        }
    }

    function picture() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/common/picture.html',
            controller: pictureController,
            controllerAs: 'vm',
            scope: {
                pictureId: '='
            }
        };
        return directive;
    }

})();

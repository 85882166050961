(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('coopDocuments', coopDocuments);


    function coopDocuments() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/coop-document/coop-documents.html'
        };

        return directive;

    }
})();


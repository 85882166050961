(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .controller('GalleryDialogController', GalleryDialogController);

    GalleryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Gallery', 'FileMetaData', 'FileUpload', '$window'];

    function GalleryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Gallery, FileMetaData, FileUpload, $window) {
        var vm = this;

        vm.gallery = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.coverpictures = FileMetaData.query({filter: 'gallery-is-null'});

        vm.editFile = editFile;
        vm.cancelFileEdition = cancelFileEdition;
        vm.updateFile = updateFile;

        $q.all([vm.gallery.$promise, vm.coverpictures.$promise]).then(function() {
            if (vm.gallery.id && vm.gallery.coverPictureId) {
                // edition
                $scope.editForm.file.$setValidity("required",true);
            }
            if (!vm.gallery.coverPictureId) {
                return $q.reject();
            }
            return FileMetaData.get({id : vm.gallery.coverPictureId}).$promise;
        }).then(function(coverPicture) {
            vm.coverpictures.push(coverPicture);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.gallery.id !== null) {
                Gallery.update(vm.gallery, onSaveSuccess, onSaveError);
            } else {
                Gallery.save(vm.gallery, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // file update if needed.
            if (vm.isFileEdition || !vm.gallery.coverPictureId) {
                vm.gallery.id = result.id
                vm.updateFile()
            } else {
                $scope.$emit('asopenessaApp:galleryUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

         function editFile () {
            vm.isFileEdition = true;
            $scope.editForm.file.$setValidity("required", false);
        }

        function cancelFileEdition () {
            angular.element("input[type='file']").val(null);
            $scope.editForm.file.$setValidity("maxSize", true);
            $scope.editForm.file.$setValidity("required", true);
            vm.file = null;
            vm.isFileEdition = false;
        }

        function updateFile() {
            FileUpload.save(vm.file, onFileUploadSuccess, onFileUploadError);
        }

        function onFileUploadSuccess(result) {
            vm.gallery.coverPictureId = result.id
            vm.isFileEdition = false;
            Gallery.update(vm.gallery, onSaveSuccess, onSaveError);
        }

        function onFileUploadError(error) {
            console.log('File upload error', error)
        }
    }
})();

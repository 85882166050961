(function() {
    'use strict';

    angular
        .module('asopenessaApp')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'maxItemsPerPage': 100,
        });
})();

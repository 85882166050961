(function() {
    'use strict';
    angular
        .module('asopenessaApp')
        .factory('FileIconService', FileIconService);

    function FileIconService () {

        return {
            getFileExtension: getFileExtension
        };

        function getFileExtension(fileExtension) {
            if (fileExtension === 'PDF') {
                return 'fa fa-file-pdf-o'
            } else if(fileExtension === 'DOC' || fileExtension === 'DOCX') {
                return 'fa fa-file-word-o';
            } else if(fileExtension === 'XLS' || fileExtension === 'XLSX') {
                return 'fa fa-file-excel-o';
            } else {
                return '';
            }
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('asopenessaApp')
        .factory('FileUpload', FileUpload);

    FileUpload.$inject = ['$resource'];

    function FileUpload ($resource) {
        var resourceUrl =  'api/files/upload';

        return $resource(resourceUrl, {}, {'save': {
            method: 'POST',
            headers: { 'Content-Type': undefined },
            transformRequest: function(data) {
               var formData = new FormData();
               formData.append("file", data);
               return formData;
             }
            }
        });
    }

})();

(function () {
    'use strict';

    angular
        .module('asopenessaApp')
        .directive('employees', employees);


    function employees() {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/about-us/employees.html'
        };

        return directive;

    }
})();

